import { useState } from 'react';
import { Table, Pagination,Spin } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import Decimal from 'decimal.js';
import dayjs from 'dayjs';
import { getBoughtProductList } from '@/api/subtitles';
import Empty from "@/components/record/empty"
import './index.less';
import "@/components/style/index.less"
import styles from '@/components/style/index.module.less';

const productNameMap: Record<string, string> = {
  subtitle_basic: 'minuteExperiencePack',
  subtitle_standard: 'minuteProfessionPack',
  subtitle_premium: 'minuteEnjoyPack',
  subtitle_deluxe: 'minuteDeluxePack',
  // TODO: 企业版
  subtitle_business_discovery: 'minuteDiscoveryPackBusiness',
  subtitle_business_essential: 'minuteEssentialPackBusiness',
  subtitle_business_professional: 'minuteProfessionalPackBusiness',
  subtitle_business_premium: 'minutePremiumPackBusiness',
  // 注册用户赠送
  subtitle_trail: 'modePayment.gift',
  // 邀请赠送
  subtitle_quota_invitation: 'modePayment.invite',
  // 迁移额度
  subtitle_migration: 'modePayment.gift',
};

const productPaymentMap: Record<string, string> = {
  QUOTA: 'modePayment.gift',
  PURCHASE: 'modePayment.recharge',
};

function BuyHistory() {
  const [curr, setCurr] = useState(1);
  const [total, setTotal] = useState(0);
  const [size, setSize] = useState(10);
  const { t } = useTranslation();

  const columns: ColumnsType<API.PlanListItem> = [
    {
      title: t('typePackage'),
      dataIndex: 'product_id',
      render: (text) => (productNameMap[text] ? t(productNameMap[text]) : text),
    },
    {
      title: t('modePayment'),
      dataIndex: 'source',
      render: (text) => (productPaymentMap[text] ? t(productPaymentMap[text]) : text),
    },
    {
      title: t('quota'),
      dataIndex: 'total',
      render: (value) => (
        t('minutes', { time: new Decimal(value).div(60).ceil().toString() })
      ),
    },
    {
      title: t('buyTime'),
      dataIndex: 'start_time',
      render: (text) => dayjs(text).format('YYYY-MM-DD HH:mm:ss'),
    },
    {
      title: t('expirationTime'),
      dataIndex: 'expire_time',
      render: (text) => (text ? dayjs(text).format('YYYY-MM-DD HH:mm:ss') : ''),
    },
  ];

  const getBoughtProductRequest = useQuery({
    queryKey: ['getBoughtProduct', curr, size],
    queryFn: () => getBoughtProductList(curr, size).then((res) => {
      if (res.data.data.total) {
        setTotal(res.data.data.total);
      }

      return res;
    }),
  });

  const onChange = (page: number, pageSize: number) => {
    setCurr(page);
    setSize(pageSize);
  };

  return (
    <div className="source-table-content">
      {getBoughtProductRequest.isLoading && <Spin className="!block mx-auto" size="large" />}
      {getBoughtProductRequest.data?.data.data.records.length === 0 && <Empty text={t('noBuy')} />}
      {
        getBoughtProductRequest.data?.data.data.records.length>0 &&
        <>
          <Table
          rowKey="user_product_id"
          pagination={false}
          columns={columns}
          loading={getBoughtProductRequest.isLoading}
          dataSource={getBoughtProductRequest.data?.data.data.records}
          className="custom-table"
          locale={{ emptyText: t('NoData') }}
          bordered={false}
        />
        <Pagination className={styles.paginationRecord} pageSize={size}showSizeChanger={false} current={curr} total={total} hideOnSinglePage onChange={onChange} />
        </>
      }
    </div>
  );
}

export default BuyHistory;
