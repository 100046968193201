import styles from '@/components/style/index.module.less';

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import logoIcon from '@/assets/images/translatorLogo.svg';
import { QRCodeCanvas } from 'qrcode.react';
import { useAuthContext } from '@/hooks/auth';
import { Button, Input, message, Result, Space, Spin } from 'antd';
import { CopyOutlined, DownloadOutlined } from '@ant-design/icons';
import css from './style.module.less';
import { getUserConfig, updateUserConfig } from '@/api/subtitles';

function QRCodeComponent({ url, onClose }: { url: string, onClose: () => void }) {
  const { t } = useTranslation();

  const copyQRCode = () => {
    navigator.clipboard.writeText(url);
    message.success(t('copyQRCodeSuccess'));
  };

  const downloadQRCode = () => {
    
    const canvas = document.getElementById("qr-gen");
    const pngUrl = canvas?.toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = "qr-code.png";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  return (
    <Space direction="vertical" align="center">
      <QRCodeCanvas
        id="qr-gen"
        className={css.shareQRCode}
        value={url}
        size={256}
        level={'H'}
        imageSettings={{ // 可以在二维码中间添加图片
          src: logoIcon,
          height: 40,
          width: 40,
          excavate: true,
        }}
      />
      <Space direction="horizontal" size={10} align="center" >
        <Input value={url} disabled style={{ width: 300 }} />
        <Button type="primary" icon={<CopyOutlined />} onClick={copyQRCode}>
          {t('copyQRCode')}
        </Button>
        <Button type="primary" icon={<DownloadOutlined />} onClick={downloadQRCode}>
          {t('downloadQRCode')}
        </Button>
      </Space>
      <Button type="primary" danger onClick={onClose} style={{ marginTop: 20 }}>
        {t('liveShare.close')}
      </Button>
    </Space>
  );
}


const SHARE_KEY = 'USER_SUBTITLE_TRANSLATE_SHARE';

const App: React.FC = () => {
  const { t } = useTranslation();
  const { state } = useAuthContext();
  const { user } = state;
  const url = `${window.location.origin}/share/${user?.uid}`;
  const [isShare, setIsShare] = useState(false);
  const [checkShare, setCheckShare] = useState(false);
  // 添加loading状态
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getUserConfig(SHARE_KEY).then(res => {
      const share: any = res.data.data;
      console.log('share', share);
      setCheckShare(!!share[SHARE_KEY]);
      setIsShare(!!share[SHARE_KEY]);
      setLoading(false);
    });
  }, []);

  const liveShare = () => {
    updateUserConfig(SHARE_KEY, {
      value: true,
    }).then(_res => {
      setIsShare(true);
      setLoading(false);
    });
  };


  // 处理点击事件
  const handleShare = () => {
    setLoading(true);
    if (checkShare) {
      setTimeout(() => {
        setIsShare(true);
        setLoading(false);
      }, 1000);
    } else {
      liveShare();
    }
  };

  const handleClose = () => {
    updateUserConfig(SHARE_KEY, {
      value: false,
    }).then(_res => {
      setIsShare(false);
    });
  };

  if (loading) {
    return <div className={styles.pageContent}>
      <div className={styles.pageTitle}>{t('shareLink')}</div>
      <div className={css.shareQRCodeWrap}>
        <div className={css.loading}>
          <Spin />
        </div>
      </div>
    </div>;
  }

  return (
    <div className={styles.pageContent}>
      <div className={styles.pageTitle}>{t('shareLink')}</div>
      <div className={css.shareQRCodeWrap}>
        {!isShare && <Result
          title={t('liveShare.noShare')}
          extra={
            <Button 
              type="primary" 
              key="console" 
              onClick={handleShare}
            >
              {t('liveShare.open')}
            </Button>
          }
        />}
        {isShare && <>
          <QRCodeComponent url={url} onClose={handleClose}/>
        </>}
      </div>
    </div>
  );
};

      export default App;