import { useState, useEffect, useRef, useMemo } from 'react';
import { Spin } from 'antd';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { getLogDetail, getShareRoomId } from '@/api/subtitles';
import css from './index.module.less';
import useShareMqtt from '@/hooks/useShareMqtt';
import { LoadingOutlined } from '@ant-design/icons';
import logoIcon from '@/assets/images/translatorLogo.svg';
// import useSubtitleMqtt from '@/hooks/useSubtitleMqtt';

interface ShareSubtitleItem {
  subtitleId?: string;
  translateText?: string;
  translateLang?: string;
  originalText?: string;
  originalLang?: string;
  resultId: string;
}

const originalResultMap: Record<string, { originalText: string; originalLang: string; resultId: string }> = {};

function Share() {
  const { t } = useTranslation();

  const { id: uid } = useParams();
  const scrollRef = useRef<HTMLDivElement>(null);
  const [isManualScroll, setIsManualScroll] = useState(false);
  const [subtitleList, setSubtitleList] = useState<ShareSubtitleItem[]>([]);
  console.log('>> uid', uid);

  const [theme, setTheme] = useState('light');
  
  // 切换主题的函数
  const toggleTheme = () => {
    const newTheme = theme === 'light' ? 'dark' : 'light';
    setTheme(newTheme);
    document.documentElement.setAttribute('data-theme', newTheme);
  };

  // 初始化主题
  useEffect(() => {
    document.documentElement.setAttribute('data-theme', theme);
  }, []);

  useEffect(() => {
    if (!isManualScroll) {
      scrollToBottom();
    }
  }, [subtitleList, isManualScroll]);
  // 滚动到底部的函数
  const scrollToBottom = () => {
    const scroll = scrollRef.current;
    if (scroll) {
      scroll.scrollTop = scroll.scrollHeight;
    }
  };

  const { data: shareData, isLoading: shareDataLoading, isError: shareDataError } = useQuery({
    queryKey: ['getShareRoomId'],
    queryFn: () => {
      return getShareRoomId(uid).then((res) => {
        console.log('>> res', res);
        return res.data.data;
      });
    },
    retry: 2,
  });
  const [shareRoomId, subtitleSegmentId] = useMemo(() => {
    if (!shareData) {
      return [];
    }

    return [shareData.room_id, shareData.user_subtitle_segment_id];
  }, [shareData]);

  const { data: logDetailData, isLoading: logDetailLoading, isError: logDetailError, refetch: logDetailRefetch, error: detailError } = useQuery({
    queryKey: ['getLogDetail', subtitleSegmentId],
    queryFn: () => {
      if (!subtitleSegmentId) {
        return Promise.resolve(null);
      }
      return getLogDetail(subtitleSegmentId).then((res) => {
        return res.data.data;
      });
    },
    retry: 2,
    enabled: !!subtitleSegmentId,
  });

  useEffect(() => {
    console.log('>> logDetailError', logDetailError, detailError?.response?.data);
  }, [logDetailError, detailError]);

  const [title, shareStatus]: [string, string] = useMemo(() => {
    if (!logDetailData) {
      return ['', 'unknown'];
    }

    setSubtitleList(logDetailData.subtitle_list.map((item) => {
      return {
        subtitleId: item.subtitle_id,
        resultId: item.subtitle_id,
        translateLang: item.subtitle_translation_list?.[0].lang || '',
        translateText: item.subtitle_translation_list?.[0].text || '',
        originalText: item.text,
      };
    }));

    return [logDetailData.title, logDetailData.status]

  }, [logDetailData]);


  useShareMqtt({
    roomId: shareRoomId,
    onMessage: (msg: API.TranslateList) => {
      console.log('>> onMessage', msg.data);

      setSubtitleList((prevData: ShareSubtitleItem[]) => {
        const nextData = [...prevData];
        const newMsg = msg.type === 'MEET_SUBTITLE_TEXT' ? {
          originalText: msg.data.originalText,
          originalLang: msg.data.originalLang,
          resultId: msg.data.resultId,
        } : {
          translateText: msg.data.translateText,
          translateLang: msg.data.translateLang,
          resultId: msg.data.resultId,
        };
        const index = nextData.findIndex((item) => item.resultId === newMsg.resultId);

        if (index > -1) {
          nextData[index] = {
            ...nextData[index],
            ...newMsg,
          };
        } else {
          nextData.push({
            ...newMsg,
          });
        }
        return nextData;
      });
    },
    onLoaded: () => {
      console.log('>> onLoaded');
      logDetailRefetch();
    },
  });

  const handleWheel = (event:any) => {
    if (event.deltaY > 0) {
      const scroll = scrollRef.current;
      if (scroll) {
        const isUserScrollUp = scroll.scrollTop < (scroll.scrollHeight - scroll.offsetHeight-2);
        setIsManualScroll(isUserScrollUp);
      }
    } else if (event.deltaY < 0) {
      setIsManualScroll(true);
    }
  };

  if (shareDataLoading || logDetailLoading) {
    return (
      <div className={css.shareContainer}>
        <div className={css.share}>
          <div className={css.pageTitle}>
            <div className={css.largeLogo}>
              <img src={logoIcon} alt="Felo Logo" />
              <span>{t('menuSubtitles')}</span>
              <Spin indicator={<LoadingOutlined spin />} size="large" />
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (shareDataError || logDetailError) {
    return (
      <div className={css.shareContainer}>
        <div className={css.share}>
          <div className={css.pageTitle}>

          <div className={css.titleText}>
            <h3>{title}</h3>
          </div>
          <div className={css.logo}>
            <img src={logoIcon} alt="Felo Logo" />
            <span>{t('menuSubtitles')}</span>
          </div>
        </div>

          <div className="flex items-center justify-center flex-1">
            <span className="text-red-500">{t('share.error')}</span>
            {detailError?.response?.data?.message ? <span className="text-red-500">({detailError?.response?.data?.message})</span> : null}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={css.shareContainer}>
    <div className={css.share}>
      <div className={css.pageTitle}>
          <div className={css.titleText}>
            <h3>{title}</h3>
            <div className={clsx(css.status, css[shareStatus])} role="button" tabIndex={0}>
              <svg width="11" height="12" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="3.286" y="1" width="4.571" height="7.429" rx="2.286" fill="currentColor" stroke="currentColor" strokeWidth=".75"></rect>
                <path d="M10.143 6.429a4.571 4.571 0 0 1-9.143 0" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round"></path>
              </svg>
              {t(`share.status.${shareStatus}`)}
            </div>
          </div>
          <div className={css.logo}>
            <img src={logoIcon} alt="Felo Logo" />
            <span>{t('menuSubtitles')}</span>
            <button 
              onClick={toggleTheme}
              className={css.themeToggle}
            >
              {theme === 'light' ? (
                <svg viewBox="0 0 24 24" width="24" height="24" className={css.moonIcon}>
                  <path fill="currentColor" d="M21.53 15.93c-.16-.27-.61-.69-1.73-.49a8.46 8.46 0 01-1.88.13 8.409 8.409 0 01-5.91-2.82 8.068 8.068 0 01-1.44-8.66c.44-1.01.13-1.54-.09-1.76s-.77-.55-1.83-.11a10.318 10.318 0 00-6.32 10.21 10.475 10.475 0 007.04 8.99 10 10 0 002.89.55c.16.01.32.02.48.02a10.5 10.5 0 008.47-4.27c.67-.93.49-1.519.32-1.79z"/>
                </svg>
              ) : (
                <svg viewBox="0 0 24 24" width="24" height="24" className={css.sunIcon}>
                  <path fill="currentColor" d="M12 7c-2.76 0-5 2.24-5 5s2.24 5 5 5 5-2.24 5-5-2.24-5-5-5zM2 13h2c.55 0 1-.45 1-1s-.45-1-1-1H2c-.55 0-1 .45-1 1s.45 1 1 1zm18 0h2c.55 0 1-.45 1-1s-.45-1-1-1h-2c-.55 0-1 .45-1 1s.45 1 1 1zM11 2v2c0 .55.45 1 1 1s1-.45 1-1V2c0-.55-.45-1-1-1s-1 .45-1 1zm0 18v2c0 .55.45 1 1 1s1-.45 1-1v-2c0-.55-.45-1-1-1s-1 .45-1 1zM5.99 4.58a.996.996 0 00-1.41 0 .996.996 0 000 1.41l1.06 1.06c.39.39 1.03.39 1.41 0s.39-1.03 0-1.41L5.99 4.58zm12.37 12.37a.996.996 0 00-1.41 0 .996.996 0 000 1.41l1.06 1.06c.39.39 1.03.39 1.41 0a.996.996 0 000-1.41l-1.06-1.06zm1.06-10.96a.996.996 0 000-1.41.996.996 0 00-1.41 0l-1.06 1.06c-.39.39-.39 1.03 0 1.41s1.03.39 1.41 0l1.06-1.06zM7.05 18.36a.996.996 0 000-1.41.996.996 0 00-1.41 0l-1.06 1.06c-.39.39-.39 1.03 0 1.41s1.03.39 1.41 0l1.06-1.06z"/>
                </svg>
              )}
            </button>
          </div>
        </div>
      <div className={css.subtitlesdetail} ref={scrollRef} onWheel={handleWheel}>
        {logDetailLoading ? (
          <main className={css.body}>
            <Spin className="!block mx-auto" size="large" />
          </main>
        ) : (
          <main className={css.body}>
            <div className={css.desc}>
              <div className={css.timeFromWrapper}>
                <span className={css.time}>
                  {dayjs(logDetailData?.gmt_create).format('YYYY-MM-DD HH:mm:ss')}
                </span>
                <em className={clsx(css.from, logDetailData?.source && css[logDetailData.source.toLocaleLowerCase().replace('_', '-')])}>
                  {logDetailData?.source.replace('_', ' ')}
                </em>
              </div>
              <a className={css.url} href={logDetailData?.source_url} target="_blank">
                {logDetailData?.source_url}
              </a>
            </div>
            <article >
              {subtitleList.map((item, index) => {
                return (
                  <div key={index} className={css.subtitleItem}>
                    <span className={css.original}>{item.originalText}</span>
                    { item.translateText && <span className={css.translate}>→ {item.translateText}</span>}
                  </div>
                );
              })}
            </article>
          </main>
        )}
      </div>
    </div>
    </div>
  );
}

export default Share;
